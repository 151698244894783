<template>
  <div id="master-data-role-list" data-app>
    
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            List Role
          </h3>
        </div>
      </div>

      <!--begin::Table-->
        <div class="card-body pt-0 pb-0">

          <b-row class="mt-3 mb-2">
            <b-col cols="12" sm="6">
              <b-button 
              class="mr-1"
              size="sm"
              variant="primary"
              @click="$router.push(`/master-data/role/add`)">
                <span class="svg-icon svg-icon-lg m-0">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/Files/Protected-file.svg"/>
                  <!--end::Svg Icon-->
                </span>
                Tambah Role
              </b-button>
            </b-col>
            <b-col cols="12" sm="6">
              <div class="input-icon h-40px max-w-300px float-sm-right">
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid h-40px"
                    placeholder="Search..."
                    v-model="search"
                  />
                  <span>
                    <span class="svg-icon svg-icon-lg">
                      <!--begin::Svg Icon-->
                      <inline-svg src="media/svg/icons/General/Search.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </div>
            </b-col>
          </b-row>

          <v-data-table
            :headers="fields"
            :items="items"
            :page.sync="currentPage"
            :items-per-page="perPage"
            hide-default-footer
            class="elevation-1"
            disable-sort
            :loading="isBusy"
            loading-text="Loading... Please wait"
          >            
            <template v-slot:[`item.status`]="{ item }">
              <b-badge :variant="item.color">{{ item.status }}</b-badge>
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <b-button 
                v-b-tooltip.hover title="Edit Role"
                class="btn-icon mr-1"
                variant="clean"
                @click="$router.push(`/master-data/role/edit/${item.value}`)">
                  <span class="svg-icon svg-icon-lg m-0">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Design/Edit.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </b-button>
                
                <b-button 
                v-b-tooltip.hover title="Nonaktif Role"
                class="btn-icon"
                variant="clean"
                @click="deletePopupData(item.value)">
                  <span class="svg-icon svg-icon-lg m-0">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Trash.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </b-button>
            </template>
          </v-data-table>
        </div>
      <!--end::Table-->  

        <div class="card-footer">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="changePage" align="center"></b-pagination>
        </div>

    </div>

    <v-dialog
    v-model="dialogDelete"
    max-width="300">
      <v-card>
        <v-card-title class="headline">Nonaktif Role</v-card-title>

        <v-card-text>
          Apakah anda yakin akan menonaktifkan role <b>{{(dataHapus ? dataHapus.nama : '')}}</b> ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <b-button
          variant="link-success"
          class="m-3"
          @click="dialogDelete = false">
            BATAL
          </b-button>

          <b-button
          variant="link-danger"
          class="m-3"
          @click="onDelete">
            HAPUS
          </b-button>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RoleService from "@/core/services/api/master/role.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "master-data-role-list",
  data() {
    return {
      search: null,
      searchTimeout: null,
      isBusy: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      fields: [{ value: "text", text: "Role" }, { value: "status", text: "Status" }, { value: "action", text: "Act" } ],
      items: [],
      dialogDelete: false,
      dataHapus: null
    }
  },
  watch: {
    search(val, old) {
      if (val != old) {
        clearTimeout(this.searchTimeout)

        this.searchTimeout = setTimeout(() => {
          this.getData(1, this.perPage, val)
        }, 750)
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    changePage(val){
      if(this.currentPage == val) return
      this.isBusy = true
      this.items = []
      this.getData(val)
    },
    resetTable() {
      this.items = []
      this.totalRows = 0
      this.currentPage = 1
    },
    getData (page = 1, perPage = this.perPage, search = this.search) {
      RoleService.getAllRole({page, per_page: perPage, search})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.resetTable()
                this.isBusy = false
                return;
            }
            this.items = res.data.data.data
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.total
            this.isBusy = false
        })
        .catch((err) => {
          this.resetTable()
          this.isBusy = false
          return ErrorService.status(err)
        })
    },
    deletePopupData(val) {
      this.dataHapus = val
      this.dialogDelete = true
    },
    onDelete () {
      if (! this.dataHapus) {
        return
      }
      RoleService.deleteRole({id: this.dataHapus})
        .then((res) => {
            if (res.data.status) { 
              this.getData(this.currentPage)              
            }
            ErrorService.message(res.data)
            this.dialogDelete = false
            this.dataHapus = null
        })
        .catch((err) => {
          this.dialogDelete = false
          this.dataHapus = null
          return ErrorService.status(err)
        })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Role" }]);
    if (!this.currentUser.menu.role) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()    
  },
};
</script>

<style lang="scss">
#master-data-role-list {
  .v-data-table {
    .v-data-table__wrapper {
      table {
        tbody {
          tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
            background: none !important;
          } 
        }
      }
    
      .v-data-table__mobile-row {
        min-height: 28px !important;
      }
    }
  }
}
</style>